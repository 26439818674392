.card {
  background: var(--surface-card);
  border: 1px solid var(--surface-border);
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: var(--card-shadow);
  border-radius: $borderRadius;

  &:last-child {
    margin-bottom: 0;
  }
}

.p-toast {
  &.p-toast-top-right,
  &.p-toast-top-left,
  &.p-toast-top-center {
    top: 100px;
  }
}

.imagem-modal {
  max-width: 200px;
  height: 200px;
  border-radius: $borderRadius;
}

.bg-inverse-primary-color {
  background-color: var(--inverse-primary-color) !important;
  background: var(--inverse-primary-color) !important;
  border-color: var(--inverse-primary-color) !important;
}

.text-inverse-primary-color {
  color: var(--inverse-primary-color) !important;
}

.exercicio-image {
  min-width: 80px;
  width: 80px;
  border: 1px solid var(--border-color);
  border-radius: $borderRadius;
  margin-right: 1rem;
}

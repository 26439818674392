.list-tile {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 1px solid var(--border-color);
  border-radius: $borderRadius;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background-color: var(--surface-100);
  }
  & .list-tile-quantidade {
    width: 80px;
    height: 80px;
    border: 1px solid var(--border-color);
    border-radius: $borderRadius;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  & .list-tile-image {
    min-width: 80px;
    width: 80px;
    border: 1px solid var(--border-color);
    border-radius: $borderRadius;
    margin-right: 1rem;
  }
  & .list-tile-titulo {
    font-size: 1.2rem;
    font-weight: 500;
  }

  & .list-tile-subtitulo {
    font-size: 1rem;
    color: var(--primary-color);
    margin-bottom: 1rem;
  }

  & .list-tile-texto {
    font-size: 1rem;
    color: var(--text-color);
    margin-top: auto;
  }
}
